import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(localizedFormat)

function parseToDuration(time: any) {
  const parsedTime = dayjs(time, 'HH:mm:ss.SSS')

  return dayjs.duration({
    hours: parsedTime.hour(),
    minutes: parsedTime.minute(),
    seconds: parsedTime.second(),
  })
}

export function formatDurationAsMinutes(time: any) {
  return Math.round(parseToDuration(time).asMinutes()) + ' min'
}

export function formatDuration(time: any) {
  const parsedTime = parseToDuration(time)

  const hours = parsedTime.hours()
  const mins = parsedTime.minutes()
  const sec = parsedTime.seconds()

  return (hours * 60 + mins) + ' min ' + sec + ' sec'
}

export function formatDate(date: any) {
  const { locale } = useI18n()
  dayjs.locale(locale.value)

  return date ? dayjs(date).format('LL') : null
}

export function formatYear(date: any) {
  return date ? dayjs(date).format('YYYY') : null
}
